import { Button, Container, Grid, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import whatsAppIcon from "../../Assets/whatsapp.png";

const SocialButton = styled(Button)({
  padding: "8px !important",
  color: "white",
  minWidth: 25,
});
export default function Footer() {
  const navigate = useNavigate();
  return (
    <Container maxWidth={false} style={{ backgroundColor: "#333", color: "white" }}>
      <Grid container direction="column" justifyContent="center" alignItems="center" pt={6} pb={6}>
        <Box>
          <Button size="medium" disableRipple style={{ textTransform: "none", color: "white" }}>
            Contact Us
          </Button>
          <Button
            size="medium"
            disableRipple
            style={{ textTransform: "none", color: "white" }}
            onClick={() => {
              navigate("/terms-and-conditions");
            }}
          >
            Terms of Use
          </Button>
          <Button
            size="medium"
            disableRipple
            style={{ textTransform: "none", color: "white" }}
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </Button>
        </Box>
        <Box mb={1}>
          <SocialButton
            onClick={() => {
              window.open(`https://www.facebook.com/MeZadeKunbi`, "_blank");
            }}
          >
            <FacebookIcon />
          </SocialButton>
          <SocialButton
            onClick={() => {
              window.open(`https://www.instagram.com/mezadekunbi`, "_blank");
            }}
          >
            <InstagramIcon />
          </SocialButton>
          <SocialButton
            onClick={() => {
              window.open(`https://wa.me/message/ET56FO5OEC47A1`, "_blank");
            }}
          >
            <Box
              component="img"
              alt=""
              src={whatsAppIcon}
            />
          </SocialButton>
        </Box>
        <Typography variant="caption">Copyright © 2022</Typography>
      </Grid>
    </Container>
  );
}
