export interface Business {
  banner_image_url: string;
  business_logo: string;
  category: string;
  city: string;
  country: string;
  createdAt: string;
  direction: string;
  email: string;
  images: Array<string>;
  name: string;
  phone: Array<string>;
  social: Array<{}>;
  state: string;
  summary: string;
  tags: Array<string>;
  updatedAt: string;
  _id: string;
}

export enum BusinessActionTypes {
  FETCH_REQUEST = "@@business/FETCH_REQUEST",
  FETCH_SUCCESS = "@@business/FETCH_SUCCESS",
  FETCH_ERROR = "@@business/FETCH_ERROR",
}

export interface BusinessState {
  readonly loading: boolean;
  readonly data: Business[];
  readonly errors?: string;
}
