import React from "react";
import "./App.scss";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme/theme";
import RouteManager from "./Routers/RouteManager";
import { Provider } from "react-redux";
import { RootState } from "./Store";
import { Store } from "redux";
interface MainProps {
  store: Store<RootState>;
}

const App: React.FC<MainProps> = ({ store }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouteManager />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
