export const API_BASE_URL = "https://zadekunbhi.herokuapp.com/";

export const API_URLS = {
  LOGIN: `user/signin`,
  REGISTRATION: `user/signup`,
  FORGOT_PASSWORD: `user/forgotPassword`,
  UPDATE_PASSWORD: `user/forgotVerify`,
  GET_USER: `user/me`, 
  FETCH_PROFILES: `/profile` 
};
