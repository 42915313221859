export default class Utility {

  static isEmptyObject = (objectToCheck) => {
    if (
      objectToCheck === null ||
      objectToCheck === undefined ||
      objectToCheck.length === 0
    )
      return true;
    if (Array.isArray(objectToCheck)) return !objectToCheck.length;
    if (typeof objectToCheck === "string") return !objectToCheck.trim().length;
    return Object.keys(objectToCheck).length === 0;
  };
  
  static encodeString(json) {
    let temp = encodeURI(json);
    return btoa(temp);
  }

  static decodeString(base64String) {
    return JSON.parse(atob(base64String));
  }

  static setPersistentValue(key, value) {
    localStorage.setItem(key, value);
  }

  static getPersistentValue(key) {
    return localStorage.getItem(key);
  }

  static setPersistentSessionValue(key, value) {
    sessionStorage.setItem(key, value);
  }

  static getPersistentSessionValue(key) {
    return sessionStorage.getItem(key);
  }
}