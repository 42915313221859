import { Label } from "@mui/icons-material";
import { Box, Button, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  paperContainer: {
    color: "white",
    background: "rgba(84, 110, 122, 0.87)",
    width: "100%",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className="w-full"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MyProfile() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid container style={{ minHeight: "71vh" }} direction="column" justifyContent="start" alignItems="start">
      <Grid style={styles.paperContainer} className="mb-8">
        <Grid container columns={{ xs: 1, sm: 1, md: 12 }} justifyContent="center" alignItems="center" p={8} className="app-container">
          <Typography variant="h1" className="text-4xl" component="h1" sx={{ textAlign: "center" }} p={1}>
            My Profile
          </Typography>
        </Grid>
      </Grid>
      <Container className="app-container pb-8" disableGutters={true}>
        <Grid direction="column" justifyContent="center" alignItems="center">
          <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab label="My Profile" {...a11yProps(0)} />
              <Tab label="Inbox" {...a11yProps(1)} />
              <Tab label="Wishlist" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container className="border px-2 py-4 border-amber-400 w-full ">
                <Typography className="w-full text-center">
                  Verify your <strong>Phone no</strong> and <strong>Email</strong> to search your dream partner
                </Typography>
                <Grid container direction={"row"} justifyContent={"center"} className="pt-2 pb-2">
                  <Box>
                    <Button color="inherit" className="mr-2 text-white bg-slate-600 px-4" startIcon={<PhoneAndroidIcon />}>
                      Phone No
                    </Button>
                  </Box>
                  <Box>
                    <Button color="inherit" className="text-white bg-slate-600 px-4" startIcon={<EmailIcon />}>
                      Email
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container className="border px-2 py-4 border-amber-400 w-full my-4">
                <Box className="border-amber-400">ashgdb mnsajhdn ,NSDjn</Box>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
              Item Three
            </TabPanel>
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
}
