import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProfilePrefState {
  profiles: [];
}
const initialState: ProfilePrefState = {
  profiles: [],
};

export const profilePrefSlice = createSlice({
  name: "profilePref",
  initialState,
  reducers: {
    getProfiles: (state, action: PayloadAction<any>) => {
      state.profiles = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { getProfiles } = profilePrefSlice.actions;
export default profilePrefSlice.reducer;
