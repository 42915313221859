import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import userService from "../../Services/userService";

export interface UserPrefState {
  isAuthenticated: boolean;
  token: string;
  userDetails: {};
}
const initialState: UserPrefState = {
  isAuthenticated: false,
  token: "",
  userDetails: {},
};

export const userRegister = createAsyncThunk("userPref/userRegister", async (data: PayloadAction<any>, thunkAPI) => {
  console.log(data);
  const response = await userService.register(data);
  return response.data;
});

export const userLogin = createAsyncThunk("userPref/userLogin", async (data: PayloadAction<any>, thunkAPI) => {
  const response = await userService.login(data);
  return response.data;
});

export const userDetails = createAsyncThunk("userPref/getUser", async (data, thunkAPI) => {
  const response = await userService.getUserDetails();
  return response.data;
});

export const userLogout = createAsyncThunk("userPref/userLogout", async (data, thunkAPI) => {
  const response = await userService.logout();
  return response;
});

export const userPrefSlice = createSlice({
  name: "userPref",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout.fulfilled, (state, action: PayloadAction<any>) => {
      console.log(action.payload);
      state.token = "";
      state.userDetails = {};
      state.isAuthenticated = false;
    });
  },
});

export const { setUser, setToken, setIsAuthenticated } = userPrefSlice.actions;
export default userPrefSlice.reducer;
