import http from "../httpCommon";
class BusinessService {
  getAll() {
    const token = localStorage.getItem("token");
    const headerConfig = {
      token: `${token}`,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return http.get("/business", { headers: headerConfig });
  }
}
export default new BusinessService();