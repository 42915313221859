import { Stack, Chip, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { API_BASE_URL } from "../../../Constants/apiConstants";

export default function PhotoCard(props: any) {
  const { data } = props;
  const getChips = (tags: any) => {
    return (
      <Stack direction="row" spacing={1}>
        {tags.map((tag: any) => (
          <Chip label={tag} color="default" size="small" />
        ))}
      </Stack>
    );
  };

  return (
    data?.data?.map((profile: any) => {
      return (
        <Grid item>
          <Card sx={{ maxWidth: 200 }}>
            <CardMedia component="img" width="190" height="194" image={API_BASE_URL + profile.profile_image} alt={profile.name} />
            <CardHeader
              titleTypographyProps={{ fontSize: "18px !important" }}
              subheaderTypographyProps={{ fontSize: "5px" }}
              title={profile.name}
              sx={{ paddingBottom: 1 }}
            />
            <CardContent sx={{ paddingTop: 0 }}>
              <Typography variant="subtitle2" color="text.secondary">
                {profile.address}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }) || null
  );
}
