import React from "react";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import BlankLayout from "../Components/Layout/BlankLayout";
import Layout from "../Components/Layout/Layout";
import BusinessListing from "../Components/pages/BusinessListing";
import ComingSoon from "../Components/pages/ComingSoon";
import Home from "../Components/pages/Home/Home";
import MatchMaking from "../Components/pages/MatchMaking/MatchMaking";
import MyProfile from "../Components/pages/myProfile";
import PageNotFound from "../Components/pages/PageNotFound";
import PrivacyPolicy from "../Components/pages/PrivacyPolicy";
import TermsAndConditions from "../Components/pages/TermsAndConditions";
import ScrollToTop from "./ScrollToTop";

export default function RouteManager() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<BlankLayout />}>
          <Route path="/" element={<ComingSoon />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/our-society-business" element={<BusinessListing />} />
          <Route path="/find-soulmate" element={<MatchMaking />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Route>
      </Routes>
    </Router>
  );

  {
    /* <Router>
<ScrollToTop />
<Layout>
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/our-society-business" element={<BusinessListing />} />
    <Route path="/find-soulmate" element={<MatchMaking />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/my-profile" element={<MyProfile />} />
    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="/coming-soon" element={<ComingSoon />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
</Layout>
</Router> */
  }
}
