import { Box, Button, Container, Grid, styled, Typography } from "@mui/material";
import logo from "../../Assets/Images/Logo/logo-circle.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import whatsAppIcon from "../../Assets/whatsapp.png";
import InstagramIcon from "@mui/icons-material/Instagram";

const SocialButton = styled(Button)({
  padding: "8px !important",
  color: "white",
  minWidth: 25,
});

const styles = {
  paperContainer: {
    color: "white",
    background: "rgba(84, 110, 122, 0.87)",
    width: "100%",
  },
};

export default function ComingSoon() {
  return (
    <Grid container>
      <Container className="app-container2" disableGutters={false} sx={{ maxWidth: "100%" }}>
        <Grid direction="column" justifyContent="center" alignItems="center">
          <div
            className="h-screen w-full flex items-center justify-center bg-cover bg-center text-center"
            // style={{
            //   backgroundImage: `url('https://images.pexels.com/photos/260689/pexels-photo-260689.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')`,
            // }}
          >
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>

            <div className="z-50 flex flex-col justify-center items-center text-white w-full h-screen">
              <Box component="img" alt="Zade Kunbi Logo" src={logo} sx={{ width: 155, m: 3 }} />
              <Typography variant="h1" className="text-4xl" component="h1" sx={{ textAlign: "center" }} p={1}>
                Zade Kunbi
              </Typography>
              {/* <Typography variant="subtitle1" className="text-l" component="h3" sx={{ textAlign: "center" }} p={1}>
                COMING SOON
              </Typography> */}
              <Typography
                variant="h2"
                className="text-2xl p-6 border-double border-4 mt-6 mb-6"
                component="h1"
                sx={{ textAlign: "center" }}
                p={1}
              >
                COMING SOON
              </Typography>
              <Box mb={1}>
                <SocialButton
                  onClick={() => {
                    window.open(`https://www.facebook.com/MeZadeKunbi`, "_blank");
                  }}
                >
                  <FacebookIcon />
                </SocialButton>
                <SocialButton
                  onClick={() => {
                    window.open(`https://www.instagram.com/mezadekunbi`, "_blank");
                  }}
                >
                  <InstagramIcon />
                </SocialButton>
                <SocialButton
                  onClick={() => {
                    window.open(`https://wa.me/message/ET56FO5OEC47A1`, "_blank");
                  }}
                >
                  <Box component="img" alt="" src={whatsAppIcon} />
                </SocialButton>
              </Box>
            </div>
          </div>
        </Grid>
      </Container>
    </Grid>
  );
}
