import { Stack, Grid, TextField, Button, CardMedia, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import ContactImage from "../../../Assets/Banner/contact-us.png";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
interface IFormInput {
  firstName: String;
  lastName: String;
  emailId: String;
  phone: String;
  message: String;
}

export default function ContactUs(props: any) {
  const form = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit = (data: any, form: any) => {
    setLoading(true);
    if (data) {
      emailjs.send("service_tsd4mrc", "template_tf6g48m", data, "R-yfmGgNK5w9NvwEr").then(
        (result) => {
          console.log(result.text);
          <Alert variant="filled" severity="success">
            Mail Sent!!!
          </Alert>;
          reset();
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          <Alert variant="filled" severity="error">
            Please try again
          </Alert>;
          reset();
          setLoading(false);
        }
      );
    }
  };
  return (
    <Grid
      container
      display="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      pt={6}
      pb={6}
      sx={{ background: "#EEEEEE" }}
    >
      <Grid className="app-container" display="flex" direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h4" pb={2} sx={{ textAlign: "center", fontWeight: "bold", textShadow: "1px 3px 1px #e3e3e3" }}>
          Contact Us
        </Typography>
        <Grid display="flex" className="w-full" direction="row" justifyContent="center" alignItems="center">
          <Grid
            // className="w-full"
            display="flex"
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <CardMedia className="w-100" component="img" image={ContactImage}></CardMedia>
          </Grid>
          <Grid display="flex" direction="column" justifyContent="center" alignItems="center" className="px-3">
            <form ref={form} onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={2} pb={3}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      id="firstName"
                      label="First Name"
                      variant="filled"
                      sx={{ input: { background: "white" } }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "First name is required" }}
                />

                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Last Name"
                      variant="filled"
                      sx={{ input: { background: "white" } }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Last name is required" }}
                />
              </Stack>
              <Stack direction="row" spacing={2} pb={3}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Phone No"
                      variant="filled"
                      sx={{ input: { background: "white" } }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                      // }}
                    />
                  )}
                  rules={{ required: "Phone no is required" }}
                />

                {/* <Controller
                  name="phone"
                  // label="Phone Number"
                  control={control}
                  rules={{ required: "Phone Number is required" }}
                  render={({ field: { onChange, value } }) => <PhoneInput country={"in"} onChange={onChange} />}
                /> */}
                {/* {error && error.message && <p className="error-message">Enter a valid Phone Number</p>} */}

                <Controller
                  name="emailId"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Email"
                      variant="filled"
                      sx={{ input: { background: "white" } }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Email is not valid",
                    },
                  }}
                />
              </Stack>
              <Stack direction="row" pb={3} style={{ width: "100%" }}>
                <Controller
                  name="message"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      className="message-text"
                      rows={4}
                      multiline
                      label="Message"
                      variant="filled"
                      sx={{ width: "100%", textarea: { background: "white" } }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Message is required" }}
                />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                <LoadingButton
                  // size="small"
                  // onClick={handleClick}
                  endIcon={<SendIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  className="bg-slate-600 text-white"
                  type="submit"
                >
                  Send
                </LoadingButton>
                {/* <Button variant="contained" className="bg-slate-600" endIcon={<SendIcon />} type="submit">
                  Send
                </Button> */}
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() => {
                    reset();
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
