import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { RootState } from "../../../Store";
import { fetchBusiness } from "../../../Store/business/action";
import { Business } from "../../../Store/business/types";
import HomePageBanner from "../../Banner/HomePageBanner";
import ContactUs from "./ContactUs";
import SectionBusiness from "./SectionBusiness";
import SectionTestimonial from "./SectionTestimonial";
import SectionVerify from "./SectionVerify";
interface PropsFromState {
  data: Business[];
  errors?: string;
}

interface propsFromDispatch {
  fetchBusiness: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const Home: React.FC<AllProps> = ({ data, fetchBusiness }) =>  {

  useEffect(() => {
    fetchBusiness();
  }, []);

  return (
    <>
      <HomePageBanner />
      <SectionTestimonial />
      <SectionVerify />
      <SectionBusiness business={data}/>
      <ContactUs />
    </>
  );
}

const mapStateToProps = ({ business }: RootState) => ({
  data: business.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchBusiness: () => {
      dispatch(fetchBusiness());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);