import { BusinessActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";


import businessService from "../../Services/businessService";

export type AppThunk = ActionCreator<
  ThunkAction<void, null, null, Action<string>>
>;

export const fetchBusiness = () => async (dispatch: Dispatch) => {
  try {
    const res = await businessService.getAll();
    dispatch({
      type: BusinessActionTypes.FETCH_REQUEST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};