import { createTheme } from "@mui/material";
// import resolveConfig from "tailwindcss/resolveConfig";
// import tailwindConfigModule from "../tailwind.config";

// const tailwindConfig = resolveConfig(tailwindConfigModule);
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#546e7a',
      // main: "#7b1fa2",
    },
    secondary: {
      main: '#f50057',
      // main: "#f50057",
    },
  },
});

export default theme;