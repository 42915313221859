import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider } from "@mui/styled-engine";
import { createBrowserHistory } from "history";
import { CssBaseline } from "@mui/material";
import { store } from "./Store";
const history = createBrowserHistory();

const initialState: any = {};
// const store = configureStore(history, initialState);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <App store={store} />
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
