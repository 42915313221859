import React from "react";
import BusinessCard from "./BusinessCard";
import skinnImage from "../../../Assets/Business/skinn-hotel.jpg";
import foodInWoodsImage from "../../../Assets/Business/food-in-woods.jpg";
import hotelStayInnSakoliHotelImage from "../../../Assets/Business/hotel-stay-inn-sakoli-hotels.webp";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const businessData = [
  {
    title: "SK Inn Hotel",
    address: "144, Subhash Nagar Rd, Trimurti Nagar, Nagpur, Maharashtra 440022",
    bannerImage: skinnImage,
    tags: ["Hotel", "AC/Non AC Rooms"],
  },
  {
    title: "Food in Woods Pure Veg.",
    address: "beside Central bank of india, Sakoli, Maharashtra 441802",
    bannerImage: foodInWoodsImage,
    tags: ["Pure Veg", "Party Order"],
  },
  {
    title: "Hotel Stay Inn",
    address: "MB Patel College Rd, Sakoli, Maharashtra 441802",
    bannerImage: hotelStayInnSakoliHotelImage,
    tags: ["Hotel", "AC/Non AC Rooms"],
  },
];
export default function SectionBusiness(props:any) {
  const navigate = useNavigate();
  const { business } = props;
  return (
    <Container className="app-container" disableGutters={true}>
      <Grid container direction="column" justifyContent="center" alignItems="center" pt={6} pb={6}>
        <Typography variant="h4" pb={4} sx={{ textAlign: "center", fontWeight: "bold", textShadow: "1px 3px 1px #e3e3e3" }}>
          Our Kunbi Samaj
        </Typography>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} style={{ paddingBottom: 32 }}>
          <BusinessCard data={business} />
        </Grid>
        <Button color="primary" variant="outlined" onClick={() => navigate("/our-society-business")}>
          View More
        </Button>
      </Grid>
    </Container>
  );
}
