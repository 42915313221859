import { API_URLS } from "../Constants/apiConstants";
import http from "../httpCommon";

class UserService {
  login(data: any) {
    return http
      .post(API_URLS.LOGIN, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  register(data: any) {
    return http
      .post(API_URLS.REGISTRATION, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  logout = () => {
    localStorage.removeItem("token");
    return true;
  };

  getUserDetails = () => {
    const token = localStorage.getItem("token");
    const headerConfig = {
      token: `${token}`,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return http
      .get(API_URLS.GET_USER, { headers: headerConfig })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  forgotPassword = (data) => {
    return http
      .post(API_URLS.FORGOT_PASSWORD, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  updatePassword = (data) => {
    return http
      .post(API_URLS.UPDATE_PASSWORD, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
export default new UserService();
