import { styled, Paper } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

export default function Testimonial(props: any) {
  const { data } = props;

  const Label = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }));

  return (
    <Masonry columns={3} spacing={2}>
      {data?.map((item: any, index: Boolean) => (
        <div key={index.toString()} className="flex justify-center items-end">
          <img
            src={`${item.img}?w=162&auto=format`}
            srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
            style={{
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              display: "block",
              width: "100%",
            }}
            className="relative flex justify-center items-end"
          />
          <Label
            className="absolute bg-transparent text-white text-2xl shadow-none"
            style={{ fontFamily: "Kaushan Script", textShadow: "black 1px 3px 1px" }}
          >
            {item.title}
          </Label>
        </div>
      ))}
    </Masonry>
  );
}
