import { Button, Grid, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: 100 }} />
      <Typography sx={{ fontSize: 40 }}>404</Typography>
      <Typography sx={{ fontSize: 30, color: "#bab1b1" }}>You are in the wrong place</Typography>
      <Button variant="contained" className="bg-slate-600" onClick={() => navigate("/")} sx={{ marginTop: 2 }}>
        Go to Home
      </Button>
    </Grid>
  );
}
