import React from "react";
import Testimonial from "./Testimonial";
import oneImage from "../../../Assets/Images/Gallery/1.jpg"
import twoImage from "../../../Assets/Images/Gallery/2.jpg"
import threeImage from "../../../Assets/Images/Gallery/3.jpg"
import fourImage from "../../../Assets/Images/Gallery/4.jpg"
import fiveImage from "../../../Assets/Images/Gallery/5.jpg"
import sixImage from "../../../Assets/Images/Gallery/6.jpg"
import sevenImage from "../../../Assets/Images/Gallery/7.jpg"
import { Container, Grid, Typography } from "@mui/material";

const businessData = [
  {
    title: "Priya & Kalpesh",
    img: oneImage,
  },
  {
    title: "Ashwini & Anwesh",
    img: twoImage,
  },
  {
    title: "Harshal & Shweta",
    img: threeImage,
  },
  {
    title: "Kalpesh & Priya",
    img: fourImage,
  },
  {
    title: "Anshul & Aditya",
    img: fiveImage,
  },
  {
    title: "Anshul & Aditya",
    img: sixImage,
  },
  {
    title: "Pronati & Mayur",
    img: sevenImage,
  },
];
export default function SectionTestimonial() {
  return (
    <Container className="app-container" disableGutters={true}>
      <Grid container direction="column" justifyContent="center" alignItems="center" pt={6} pb={6}>
        <Typography variant="h4" pb={4} sx={{ textAlign: "center", fontWeight: "bold", textShadow: "1px 3px 1px #e3e3e3" }}>
          Success Story
        </Typography>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingBottom: 32 }}>
          <Testimonial data={businessData} />
        </Grid>
      </Grid>
    </Container>
  );
}
