import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import bannerImage from "../../Assets/Banner/banner.jpg";
import Jodi from "../../Assets/Banner/jodi-1.png";
import Jodi2 from "../../Assets/Banner/jodi-2.png";
import { useAppSelector } from "../../Store/hook";

const styles = {
  paperContainer: {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 475,
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    borderRadius: 0,
  },
  heading: {
    fontSize: 35,
    textShadow: "1px 3px 1px #000000",
    fontWeight: "bold",
  },
};

function HomePageBanner() {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.userPref.isAuthenticated);
  return (
    <Grid style={styles.paperContainer}>
      <Grid display="flex" flexDirection="row" justifyContent="center" alignItems="center" className="app-container">
        <Box
          component="img"
          alt="Jodi 1"
          src={Jodi}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        />
        <Grid flexDirection="column" textAlign="center">
          <Typography variant="h1" style={styles.heading} color="white" component="div" pb={3} fontWeight="bold" fontSize={12}>
            LOOKING FOR LIFE PARTNER
          </Typography>
          {!isAuthenticated && (
            <Button
              variant="contained"
              color="primary"
              className="bg-slate-600"
              onClick={() => {
                navigate("/");
              }}
            >
              Register Now
            </Button>
          )}
          {isAuthenticated && (
            <Button
              variant="contained"
              color="primary"
              className="bg-slate-600"
              onClick={() => {
                navigate("/find-soulmate");
              }}
            >
              Find Partner
            </Button>
          )}
        </Grid>
        <Box
          component="img"
          alt="Jodi 2"
          src={Jodi2}
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default HomePageBanner;
