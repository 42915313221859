import { Stack, Chip, Grid, IconButton, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import { API_BASE_URL } from "../../../Constants/apiConstants";

export default function BusinessCard(props: any) {
  const { data } = props;
  const getChips = (tags: any) => {
    return (
      <Stack direction="row" spacing={1}>
        {tags.map((tag: any) => (
          <Chip label={tag} color="default" size="small" className="" />
        ))}
      </Stack>
    );
  };

  return data?.data?.map((business: any) => {
    return (
      <Grid item>
        <Card sx={{ maxWidth: 335 }}>
          <CardMedia component="img" height="350" className="h-60" image={API_BASE_URL+business.banner_image_url} alt={business.name} />
          <CardHeader
            titleTypographyProps={{ fontSize: "19px !important" }}
            title={business.name}
            sx={{ paddingBottom: 0 }}
          />
          <CardContent sx={{ paddingTop: 0 }}>
            <Typography variant="subtitle2" className="text-sm pb-2" color="text.secondary">
              {business.city}, {business.state}
            </Typography>
            <Typography variant="subtitle1" className="text-sm pb-2" color="inherit">
              {business.category}
            </Typography>
            <Tooltip title="Mail To">
              <IconButton color="primary" aria-label="Email" className="pr-1.5" sx={{ p: 0 }}>
                <EmailIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Get Direction">
              <IconButton color="primary" aria-label="Direction" className="px-1.5" sx={{ p: 0 }}>
                <AssistantDirectionIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Contact No">
              <IconButton color="primary" aria-label="Contact No" className="px-1.5" sx={{ p: 0 }}>
                <PhoneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Twitter">
              <IconButton color="primary" aria-label="Twitter" className="px-1.5" sx={{ p: 0 }}>
                <TwitterIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Facebook">
              <IconButton color="primary" aria-label="Facebook" className="px-1.5" sx={{ p: 0 }}>
                <FacebookIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="WhatsApp">
              <IconButton color="primary" aria-label="WhatsApp" className="px-1.5" sx={{ p: 0 }}>
                <WhatsAppIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Youtube">
              <IconButton color="primary" aria-label="Youtube" className="px-1.5" sx={{ p: 0 }}>
                <YouTubeIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Telegram">
              <IconButton color="primary" aria-label="Telegram" className="px-1.5" sx={{ p: 0 }}>
                <TelegramIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Instagram">
              <IconButton color="primary" aria-label="Instagram" className="px-1.5" sx={{ p: 0 }}>
                <InstagramIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </CardContent>
        </Card>
      </Grid>
    );
  }) || null;
}
