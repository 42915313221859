import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, Fade, IconButton, Modal, Stack, TextField, Typography, Grid, Button, Alert, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LoginIcon from "@mui/icons-material/Login";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../Store/hook";
import Utility from "../../Utility/Util";
import { setIsAuthenticated, setToken, setUser } from "../../Store/slices/userPrefSlice";
import UserService from "../../Services/userService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export interface ILogin {
  openModal: boolean;
  onClose?: () => void;
  onShowLogin?: () => void;
}

interface IFormInput {
  email: String;
  password: String;
  firstName: String;
  lastName: String;
  phone: String;
}

export default function Login(props: ILogin) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const form = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});

  const onCloseModal = () => {
    handleClose();
    if (props.onClose) props.onClose();
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();

  useEffect(() => {
    props.openModal && handleOpen();
    reset()
    setFormError("");
  }, [props.openModal]);

  const onSubmit = (reqObject: any, form: any) => {
    setLoading(true);
    setFormError({});
    if (reqObject) {
      reqObject.password = Utility.encodeString(reqObject.password);
      UserService.register(reqObject)
        .then((res) => {
          if (res) {
            onCloseModal();
            dispatch(setToken(res.data.token));
            dispatch(setUser(res.data.user));
            dispatch(setIsAuthenticated(true));
            reset();
            localStorage.setItem("token", (res as any)?.data.token);
          }
        })
        .catch((error) => {
          setLoading(false);
          setFormError(error.response.data);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onCloseModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack direction="row" justifyContent="end" alignItems="end">
            <IconButton disableRipple={false} disableFocusRipple={false} onClick={() => onCloseModal()}>
              <ClearIcon />
            </IconButton>
          </Stack>
          <Grid display="flex" direction="column" justifyContent="center" alignItems="center" pl={3} pr={3} pb={3}>
            <Typography variant="h6" component="h2" style={{ marginBottom: 25, textAlign: "center" }}>
              Let's set up your account, while we find Matches for you!
            </Typography>
            <form ref={form} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    className="w-full mb-4"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                rules={{ required: "First Name is required" }}
              />
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    className="w-full mb-4"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                rules={{ required: "Last Name is required" }}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    className="w-full mb-4"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                rules={{ required: "Email is required" }}
              />
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="phone"
                    label="Phone No"
                    variant="outlined"
                    className="w-full mb-4"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                    }}
                  />
                )}
                rules={{ required: "Phone no is required" }}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    autoComplete="true"
                    id="current-password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    className="w-full mb-4"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                rules={{ required: "Password is required" }}
              />
              <LoadingButton
                endIcon={<LoginIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                style={{ fontSize: 16, textTransform: "none" }}
                className="bg-slate-600 text-white w-full"
                type="submit"
              >
                Login
              </LoadingButton>
            </form>
            {!Utility.isEmptyObject(formError) && (
              <Alert severity="error" className="mt-3 w-full">
                {(formError as any).msg}
              </Alert>
            )}
            <Stack direction="row" justifyContent="center" className="w-full mt-4">
              <Button
                size="small"
                style={{ textTransform: "none" }}
                onClick={() => {
                  onCloseModal();
                  props.onShowLogin && props.onShowLogin();
                }}
              >
                Already a Member? Login
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
