import { configureStore } from "@reduxjs/toolkit";
import { BusinessReducer } from "./business/reducer";
import profilePrefReducer from "./slices/profilePrefSlice"
import userPrefReducer from "./slices/userPrefSlice";

export const store = configureStore({
  reducer: {
    userPref: userPrefReducer,
    business: BusinessReducer,
    profilePref: profilePrefReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
