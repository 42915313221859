import { API_URLS } from "../Constants/apiConstants";
import http from "../httpCommon";

class ProfilesService {
  fetchProfiles = () => {
    const token = localStorage.getItem("token");
    const headerConfig = {
      token: `${token}`,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return http
      .get(API_URLS.FETCH_PROFILES, { headers: headerConfig })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
export default new ProfilesService();
