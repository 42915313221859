import React from "react";
import Testimonial from "./Testimonial";
import { Box, Container, Grid, Typography } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import SecurityIcon from "@mui/icons-material/Security";

const styles = {
  paperContainer: {
    color: "white",
    background: "rgba(84, 110, 122, 0.87)",
    width: "100%",
  },
};

export default function SectionVerify() {
  return (
    <Grid container style={styles.paperContainer} className="app-container">
      <Grid
        container
        columns={{ xs: 1, sm: 1, md: 12 }}
        justifyContent="space-between"
        alignItems="center"
        p={4}
        // pb={4}
        className="app-container"
      >
        <Grid item>
          <Typography variant="h5" component="h3" sx={{ textAlign: "center" }} p={1}>
            <SupervisedUserCircleIcon fontSize="large" sx={{ verticalAlign: "bottom" }} /> Best Matches
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h3" sx={{ textAlign: "center" }} p={1}>
            <VerifiedIcon fontSize="large" sx={{ verticalAlign: "bottom" }} /> Verified Profiles
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h3" sx={{ textAlign: "center" }} p={1}>
            <SecurityIcon fontSize="large" sx={{ verticalAlign: "bottom" }} /> 100% Privacy
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
