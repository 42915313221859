import { makeStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';
import Navbar from "../../Navbars/Navbar";
import Footer from "../Footer/Footer";

// const theme = createTheme();
const useStyles =  makeStyles({
  layout: {
    margin: 0,
    // maxWidth: 1250,
    // background: '#f9f9f9',
  },
});
export default function Layout({ children }: any) {
  const classes = useStyles()
  return (
    <div className={classes.layout}>
      <Navbar />
      {children}
      <Outlet />
      <Footer />
    </div>
  );
}
