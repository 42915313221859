import { Grid, Box, Typography, Button, MenuItem, Select, Pagination, Stack, FormControl, InputLabel } from "@mui/material";
import React, { useEffect } from "react";
import bannerImage from "../../Assets/Banner/banner.jpg";
import Jodi from "../../Assets/Banner/jodi-1.png";
import BusinessCard from "./Home/BusinessCard";
import ContactUs from "./Home/ContactUs";
import { RootState } from "../../Store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { fetchBusiness } from "../../Store/business/action";
import { connect } from "react-redux";
import { Business } from "../../Store/business/types";

const styles = {
  paperContainer: {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 300,
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    borderRadius: 0,
  },
  heading: {
    fontSize: 35,
    textShadow: "1px 3px 1px #000000",
    fontWeight: "bold",
  },
};

interface PropsFromState {
  data: Business[];
  errors?: string;
}

interface propsFromDispatch {
  fetchBusiness: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const BusinessListing: React.FC<AllProps> = ({ data, fetchBusiness }) => {
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");

  useEffect(() => {
    fetchBusiness();
  }, []);

  const getHeaderSection = () => {
    return (
      <Grid style={styles.paperContainer}>
        <Grid display="flex" flexDirection="row" justifyContent="center" alignItems="center" className="app-container">
          <Box
            component="img"
            alt="Jodi 1"
            mr={5}
            src={Jodi}
            sx={{
              width: 250,
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          <Grid flexDirection="column" textAlign="center">
            <Typography variant="h1" style={styles.heading} color="white" component="div" fontWeight="bold" fontSize={12}>
              Do you want to listen to your business?
            </Typography>
            <Typography variant="h1" style={styles.heading} color="white" component="div" pb={3} fontWeight="bold" fontSize={12}>
              It's completely free
            </Typography>
            <Button variant="contained" color="primary" className="bg-slate-600">
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getFilterSection = () => {
    return (
      <Grid container pt={4} pb={4} sx={{ background: "#EEEEEE" }}>
        <Grid
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          className="app-container"
          sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}
        >
          <Typography>Search Location: </Typography>
          <FormControl sx={{ m: 1, width: 250 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              label="State"
              // displayEmpty
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value={`andhra-pradesh`}>Andhra Pradesh</MenuItem>
              <MenuItem value={`Arunachal pradesh`}>Arunachal Pradesh</MenuItem>
              <MenuItem value={`assam`}>Assam</MenuItem>
              <MenuItem value={`bihar`}>Bihar</MenuItem>
              <MenuItem value={`chhattisgarh`}>Chhattisgarh</MenuItem>
              <MenuItem value={`goa`}>Goa</MenuItem>
              <MenuItem value={`gujarat`}>Gujarat</MenuItem>
              <MenuItem value={`haryana`}>Haryana</MenuItem>
              <MenuItem value={`Himachal pradesh`}>Himachal Pradesh</MenuItem>
              <MenuItem value={`jharkhand`}>Jharkhand</MenuItem>
              <MenuItem value={`karnataka`}>Karnataka</MenuItem>
              <MenuItem value={`kerala`}>Kerala</MenuItem>
              <MenuItem value={`madhya-pradesh`}>Madhya Pradesh</MenuItem>
              <MenuItem value={`maharashtra`}>Maharashtra</MenuItem>
              <MenuItem value={`manipur`}>Manipur</MenuItem>
              <MenuItem value={`meghalaya`}>Meghalaya</MenuItem>
              <MenuItem value={`mizoram`}>Mizoram</MenuItem>
              <MenuItem value={`nagaland`}>Nagaland</MenuItem>
              <MenuItem value={`odisha`}>Odisha</MenuItem>
              <MenuItem value={`punjab`}>Punjab</MenuItem>
              <MenuItem value={`rajasthan`}>Rajasthan</MenuItem>
              <MenuItem value={`sikkim`}>Sikkim</MenuItem>
              <MenuItem value={`tamil-nadu`}>Tamil Nadu</MenuItem>
              <MenuItem value={`telangana`}>Telangana</MenuItem>
              <MenuItem value={`tripura`}>Tripura</MenuItem>
              <MenuItem value={`uttar-pradesh`}>Uttar Pradesh</MenuItem>
              <MenuItem value={`uttarakhand`}>Uttarakhand</MenuItem>
              <MenuItem value={`gairsain`}>Gairsain</MenuItem>
              <MenuItem value={`west-bengal`}>West Bengal</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 250 }}>
            <InputLabel id="city-label">City</InputLabel>
            <Select
              labelId="city-label"
              id="city"
              label="City"
              // displayEmpty
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value={`ahmednagar`}>Ahmednagar</MenuItem>
              <MenuItem value={`akola`}>Akola</MenuItem>
              <MenuItem value={`amravati`}>Amravati</MenuItem>
              <MenuItem value={`aurangabad`}>Aurangabad</MenuItem>
              <MenuItem value={`beed`}>Beed</MenuItem>
              <MenuItem value={`bhandara`}>Bhandara</MenuItem>
              <MenuItem value={`buldhana`}>Buldhana</MenuItem>
              <MenuItem value={`chandrapur`}>Chandrapur</MenuItem>
              <MenuItem value={`dhule`}>Dhule</MenuItem>
              <MenuItem value={`gadchiroli`}>Gadchiroli</MenuItem>
              <MenuItem value={`gondia`}>Gondia</MenuItem>
              <MenuItem value={`hingoli`}>Hingoli</MenuItem>
              <MenuItem value={`jalgaon`}>Jalgaon</MenuItem>
              <MenuItem value={`jalna`}>Jalna</MenuItem>
              <MenuItem value={`kolhapur`}>Kolhapur</MenuItem>
              <MenuItem value={`latur`}>Latur</MenuItem>
              <MenuItem value={`mumbai-city`}>Mumbai City</MenuItem>
              <MenuItem value={`mumbai-suburban`}>Mumbai Suburban</MenuItem>
              <MenuItem value={`nagpur`}>Nagpur</MenuItem>
              <MenuItem value={`nanded`}>Nanded</MenuItem>
              <MenuItem value={`nandurbar`}>Nandurbar</MenuItem>
              <MenuItem value={`nashik`}>Nashik</MenuItem>
              <MenuItem value={`osmanabad`}>Osmanabad</MenuItem>
              <MenuItem value={`palghar`}>Palghar</MenuItem>
              <MenuItem value={`parbhani`}>Parbhani</MenuItem>
              <MenuItem value={`pune`}>Pune</MenuItem>
              <MenuItem value={`raigad`}>Raigad</MenuItem>
              <MenuItem value={`ratnagiri`}>Ratnagiri</MenuItem>
              <MenuItem value={`sangli`}>Sangli</MenuItem>
              <MenuItem value={`satara`}>Satara</MenuItem>
              <MenuItem value={`sindhudurg`}>Sindhudurg</MenuItem>
              <MenuItem value={`solapur`}>Solapur</MenuItem>
              <MenuItem value={`thane`}>Thane</MenuItem>
              <MenuItem value={`wardha`}>Wardha</MenuItem>
              <MenuItem value={`washim`}>Washim</MenuItem>
              <MenuItem value={`yavatmal`}>Yavatmal</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" className="bg-slate-600">
            Filter
          </Button>
        </Grid>
      </Grid>
    );
  };

  // const businessData = [
  //   {
  //     title: "SK Inn Hotel",
  //     address: "144, Subhash Nagar Rd, Trimurti Nagar, Nagpur, Maharashtra 440022",
  //     bannerImage: skinnImage,
  //     tags: ["Hotel", "AC/Non AC Rooms"],
  //   },
  //   {
  //     title: "Food in Woods Pure Veg.",
  //     address: "beside Central bank of india, Sakoli, Maharashtra 441802",
  //     bannerImage: foodInWoodsImage,
  //     tags: ["Pure Veg", "Party Order"],
  //   },
  //   {
  //     title: "Hotel Stay Inn",
  //     address: "MB Patel College Rd, Sakoli, Maharashtra 441802",
  //     bannerImage: hotelStayInnSakoliHotelImage,
  //     tags: ["Hotel", "AC/Non AC Rooms"],
  //   },
  //   {
  //     title: "SK Inn Hotel",
  //     address: "144, Subhash Nagar Rd, Trimurti Nagar, Nagpur, Maharashtra 440022",
  //     bannerImage: skinnImage,
  //     tags: ["Hotel", "AC/Non AC Rooms"],
  //   },
  //   {
  //     title: "Food in Woods Pure Veg.",
  //     address: "beside Central bank of india, Sakoli, Maharashtra 441802",
  //     bannerImage: foodInWoodsImage,
  //     tags: ["Pure Veg", "Party Order"],
  //   },
  //   {
  //     title: "Hotel Stay Inn",
  //     address: "MB Patel College Rd, Sakoli, Maharashtra 441802",
  //     bannerImage: hotelStayInnSakoliHotelImage,
  //     tags: ["Hotel", "AC/Non AC Rooms"],
  //   },
  //   {
  //     title: "SK Inn Hotel",
  //     address: "144, Subhash Nagar Rd, Trimurti Nagar, Nagpur, Maharashtra 440022",
  //     bannerImage: skinnImage,
  //     tags: ["Hotel", "AC/Non AC Rooms"],
  //   },
  //   {
  //     title: "Food in Woods Pure Veg.",
  //     address: "beside Central bank of india, Sakoli, Maharashtra 441802",
  //     bannerImage: foodInWoodsImage,
  //     tags: ["Pure Veg", "Party Order"],
  //   },
  //   {
  //     title: "Hotel Stay Inn",
  //     address: "MB Patel College Rd, Sakoli, Maharashtra 441802",
  //     bannerImage: hotelStayInnSakoliHotelImage,
  //     tags: ["Hotel", "AC/Non AC Rooms"],
  //   },
  // ];

  const bussinessList = () => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" pt={6} pb={6}>
        <Typography variant="h4" pb={4} sx={{ textAlign: "center", fontWeight: "bold", textShadow: "1px 3px 1px #e3e3e3" }}>
          Our Samaj Bussiness
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className="app-container"
          sx={{ justifyContent: { xs: "center", sm: "center", md: "flex-start" } }}
        >
          {console.log(data)}
          <BusinessCard data={data} />
          <Grid container direction="row" justifyContent="center" alignItems="start" pt={6}>
            <Stack spacing={2}>
              <Pagination count={10} showFirstButton showLastButton />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container display="column">
      {getHeaderSection()}
      {getFilterSection()}
      {bussinessList()}
      <ContactUs />
    </Grid>
  );
};

const mapStateToProps = ({ business }: RootState) => ({
  data: business?.data || [],
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchBusiness: () => {
      dispatch(fetchBusiness());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessListing);
