import React, { Children } from "react";
import { Outlet } from "react-router-dom";

const BlankLayout = ({ props }: any) => {
  const arrayChildren = Children.toArray(props);
  console.log("layout blank", arrayChildren);
  return (
    <>
      <Outlet />
    </>
  );
};

export default BlankLayout;
