import React, { useEffect } from "react";

import Login from "../Components/Modal/Login";
import Register from "../Components/Modal/Register";
import logo from "../Assets/Images/Logo/logo-circle.png";
import LoginIcon from "@mui/icons-material/Login";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  Grid,
  ButtonGroup,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAppDispatch, useAppSelector } from "../Store/hook";
import { setIsAuthenticated, setToken, setUser, userLogout } from "../Store/slices/userPrefSlice";
import ForgotPassword from "../Components/Modal/ForgotPassword";
import VerifyForgotPassword from "../Components/Modal/VerifyForgotPassword";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserService from "../Services/userService";
import Utility from "../Utility/Util";

export const Navbar = () => {
  const [needLogin, setNeedLogin] = React.useState(false);
  const [needRegistration, setNeedRegistration] = React.useState(false);
  const [needForgotPassword, setNeedForgotPassword] = React.useState(false);
  const [needVerifyForgotPassword, setNeedVerifyForgotPassword] = React.useState(false);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.userPref.isAuthenticated);
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    dispatch(userLogout());
  };

  const handleToggle = () => {
    setOpenLogout((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenLogout(false);
  };

  useEffect(() => {
    if (!isAuthenticated && Utility.getPersistentValue("token")) {
      fetchUserDetails();
    }
  }, []);

  const fetchUserDetails = () => {
    UserService.getUserDetails()
      .then((res) => {
        console.log(res);
        if (res) {
          dispatch(setToken(Utility.getPersistentValue("token")));
          dispatch(setUser(res.data));
          dispatch(setIsAuthenticated(true));
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        navigate("/");
      });
  };

  return (
    // <div className={classes.root}>
    <>
      <AppBar position="sticky" color="primary">
        <Toolbar
          className="app-container"
          sx={{ display: "flex", justifyContent: "space-between", paddingLeft: "0px !important", paddingRight: "0px !important" }}
        >
          {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
  </IconButton> */}
          <Grid
            display="flex"
            direction="row"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              navigate("/");
            }}
          >
            <Box component="img" alt="Zade Kunbi Logo" src={logo} sx={{ width: 43, m: 1 }} />
            <Typography variant="h6" color="inherit" sx={{ cursor: "pointer" }}>
              Zade Kubhi
            </Typography>
          </Grid>
          {!isAuthenticated && (
            <Button variant="outlined" color="inherit" sx={{ mr: 1 }} startIcon={<LoginIcon />} onClick={() => setNeedLogin(true)}>
              Login
            </Button>
          )}

          {isAuthenticated && (
            // <Button color="inherit" sx={{ mr: 1 }} onClick={() => handleLogout()}>
            //   <AccountCircleIcon />
            // </Button>
            <div className="flex flex-row justify-center items-center">
              <Button
                variant="outlined"
                color="inherit"
                sx={{ mr: 1 }}
                onClick={() => {
                  navigate("/find-soulmate");
                }}
              >
                Search Soulmate
              </Button>
              <div className="flex-col">
                <ButtonGroup variant="contained" ref={anchorRef} disableElevation aria-label="split button">
                  <Button
                    size="small"
                    aria-controls={openLogout ? "split-button-menu" : undefined}
                    aria-expanded={openLogout ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <AccountCircleIcon />
                  </Button>
                </ButtonGroup>
                <Popper open={openLogout} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            <MenuItem onClick={() => navigate(`/my-profile`)}>Profile</MenuItem>
                            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          )}

          {/* {isAuthenticated && (
            <Button variant="outlined" color="inherit" sx={{ mr: 1 }} startIcon={<LogoutIcon />} onClick={() => handleLogout()}>
              Logout
            </Button>
          )} */}
        </Toolbar>
      </AppBar>
      <Login
        openModal={needLogin}
        onClose={() => {
          setNeedLogin(false);
        }}
        onShowRegister={() => {
          setNeedLogin(false);
          setNeedRegistration(true);
        }}
        onShowForgot={() => {
          setNeedForgotPassword(true);
          setNeedLogin(false);
        }}
      />
      <Register
        openModal={needRegistration}
        onClose={() => {
          setNeedRegistration(false);
        }}
        onShowLogin={() => {
          setNeedRegistration(false);
          setNeedLogin(true);
        }}
      />
      <ForgotPassword
        openModal={needForgotPassword}
        onClose={() => {
          setNeedForgotPassword(false);
        }}
        onShowLogin={() => {
          setNeedForgotPassword(false);
          setNeedLogin(true);
        }}
        onShowVerifyPassword={() => {
          setNeedForgotPassword(false);
          setNeedVerifyForgotPassword(true);
        }}
      />
      <VerifyForgotPassword
        openModal={needVerifyForgotPassword}
        onClose={() => {
          setNeedVerifyForgotPassword(false);
        }}
        onShowLogin={() => {
          setNeedVerifyForgotPassword(false);
          setNeedLogin(true);
        }}
      />
    </>
    // </div>
  );
};

export default Navbar;
